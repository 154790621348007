<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">待结算列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc  operationControlWidth"
          style="align-items: flex-start;"
        >
          <div class="searchbox" style="margin-bottom:5px">
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>

              <el-cascader
                clearable
                filterable
                v-model="searchData.areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
              ></el-cascader>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="searchData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="排序方式" class="searchboxItem ci-full">
              <span class="itemLabel">排序方式:</span>
              <el-select
                size="small"
                v-model="searchData.sortMethod"
                clearable
                placeholder="请选择排序方式"
                style="width:100%"
              >
                <el-option value="1" label="待结算数量倒序"></el-option>
                <el-option value="2" label="按已生成结算单倒序"></el-option>
              </el-select>
            </div>

            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div style="margin: 15px 0.675rem 5px">
          <span>合计班级数量：{{ projectCount }}</span>
          <span style="margin:0 30px;">合计待生成结算单班级：{{ waitProjectCount }}</span>
          <span style="margin:0 30px;">合计已生成结算单班级：{{ settlementProjectCount }}</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaName"
                min-width="150px"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                min-width="250px"
              />
              <el-table-column
                label="班级数量"
                align="right"
                show-overflow-tooltip
                prop="projectCount"
                min-width="250px"
              />
              <el-table-column
                label="待生成结算单班级数量"
                align="right"
                show-overflow-tooltip
                prop="waitProjectCount"
                min-width="250px"
              />
              <el-table-column
                label="已生成结算单班级数量"
                align="right"
                show-overflow-tooltip
                prop="settlementProjectCount"
                min-width="250px"
              />

              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <template slot-scope="scope">
                  <div style="text-align:left;" >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding:0 5px"
                      @click="goStatementListIng(scope.row.compId,scope.row.compName)"
                    >结算单管理</el-button>
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
const className = "eduCertService";

import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";

export default {
  name: "evaluate/statementList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      searchData: {
        sortMethod: "",
        compId: "",
        areaId: ""
      },
      areatreeList: [],
      CompanyList: [],
      projectCount:'',    
      settlementProjectCount:'',    
      waitProjectCount:'',   
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getareatree();
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if(this.searchData.sortMethod) {
        params.sortMethod = this.searchData.sortMethod
      }
      if(this.searchData.areaId) {
        params.areaId = this.searchData.areaId
      }
      if(this.searchData.compId) {
        params.compId = this.searchData.compId
      }
      this.doFetch({
        url: "/run/settlement/bill/page",
        params,
        pageNum
      });
      this.getTotal()
    },
    getTotal() {
      let parmar = {};
      if(this.searchData.sortMethod) {
        parmar.sortMethod = this.searchData.sortMethod
      }
      if(this.searchData.areaId) {
        parmar.areaId = this.searchData.areaId
      }
      if(this.searchData.compId) {
        parmar.compId = this.searchData.compId
      }
      this.$post('/run/settlement/bill/total',parmar).then(ret => {
        const retData = ret.data;
        this.projectCount = retData.projectCount
        this.settlementProjectCount = retData.settlementProjectCount
        this.waitProjectCount = retData.waitProjectCount

      }).catch(err => {
        return;
      })
    },
    //去结算单管理
    goStatementListIng(compId,compName) {
      this.$router.push({
        path: "/web/evaluate/statementListIng",
        query: {
          compId,
          compName
        }
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight -
        (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.projectUser {
  min-width: 6rem !important;
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 300px;
    height: 300px;
    background-color: #999; //设置白色背景色
  }
}
.leftData {
  display: flex;
  flex-direction: column;
  img {
    width: 300px;
    height: 300px;
  }
  span {
    display: flex;
    width: 300px;
  }
}

.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.el-divider--horizontal {
  margin: 13px 0;
}
.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;
  .dailyRemind-1 {
    margin-bottom: 20px;
    p {
      margin-top: 10px;
    }
  }
  .dailyRemind-2 {
    p {
      margin-top: 10px;
    }
  }
}
</style>
